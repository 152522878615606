<template>
  <div style="padding: 10px">
    <div>
      <div
        v-loading="topLoading"
        class="flex flex-wrap justify-around align-center"
        style="height: 100%; padding: 10px"
      >
        <div class="flex align-center total-box">
          <div class="total-name" style="background: rgb(45, 140, 240)">日</div>
          <div class="total-info">
            <div class="total-info-num">{{ topData.todayCreateTotal }}</div>
            <div class="total-info-txt">今日添加微信</div>
          </div>
        </div>
        <div class="flex align-center total-box">
          <div class="total-name" style="background: rgb(25, 190, 107)">周</div>
          <div class="total-info">
            <div class="total-info-num">{{ topData.thisWeekCreateTotal }}</div>
            <div class="total-info-txt">本周添加</div>
          </div>
        </div>
        <div class="flex align-center total-box">
          <div class="total-name" style="background: rgb(228, 108, 187)">
            月
          </div>
          <div class="total-info">
            <div class="total-info-num">{{ topData.thisMonthCreateTotal }}</div>
            <div class="total-info-txt">本月添加</div>
          </div>
        </div>
        <div class="flex align-center total-box">
          <div class="total-name" style="background: rgb(154, 102, 228)">
            总
          </div>
          <div class="total-info">
            <div class="total-info-num">{{ topData.totalCreateTotal }}</div>
            <div class="total-info-txt">总添加</div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div style="padding: 20px 0">
        <el-date-picker
          value-format="yyyy-MM"
          size="mini"
          v-model="date"
          type="month"
          @change="change"
          placeholder="选择月"
        >
        </el-date-picker>
      </div>
    </div>
    <div v-loading="loading">
      <div
        id="everyday"
        style="height: 600px; width: 100%; margin: 20px 0"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
let myChart;
export default {
  name: "salesStatisticsChart",
  data() {
    return {
      topData: {
        todayCreateTotal: "0", // 今日微信总录入数
        thisWeekCreateTotal: "0", // 本周微信总录入数
        thisMonthCreateTotal: "0", // 本月微信总录入数
        totalCreateTotal: "0", // 历史微信总录入数
      },
      topLoading: false,
      loading: false,
      date: new Date(),
    };
  },
  methods: {
    ...mapActions("flow", ["statisticsTop", "statisticsData"]),
    change(e) {
      console.log(e);
      this.getDetail();
    },
    getDetail() {
      this.getDayChart();
    },
    async getDayChart() {
      this.loading = true;
      const res = await this.statisticsData({ month: this.date });
      if (myChart != null && myChart != "" && myChart != undefined) {
        myChart.dispose();
      }
      myChart = this.$echarts.init(document.getElementById("everyday"));
      this.loading = false;
      let users = [];
      let day = [];

      let userData = [];
      let da = [];
      let total = 0;
      let array = [];
      let totalArr = [];
      res.data.creatorList.forEach((user) => {
        users.push(user.name);
        for (let resKey in res.data.data) {
          if (!day.includes(resKey)) day.push(resKey);

          res.data.data[resKey].forEach((item) => {
            item.forEach((i) => {
              if (user.user_id == i.user_id) {
                da.push(i.count);
              }
            });
          });
        }
        userData.push({
          name: user.name,
          type: "bar",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: da,
        });
        da = [];
      });
      let i = 0;

      for (let resKey in res.data.data) {
        userData.forEach((user) => {
          if (user.data[i]) total += user.data[i];
        });
        i++;
        totalArr.push(total);
        total = 0;
      }
      i = 0;
      if(userData[userData.length - 1] != undefined){
        userData[userData.length - 1].label = {
        show: true,
        position: "top",
        formatter: function (p) {
          return totalArr[p.dataIndex];
        },
      };
      }else{
        userData = []
      }
      
      
      let option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: users,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {},
        xAxis: {
          type: "category",
          data: day,
        },
        yAxis: {
          type: "value",
          max: function (value) {
            return value.max + 10;
          },
        },
        series: userData,
      };
      myChart.setOption(option, true);
      option = null;
    },
    async init() {
      this.topLoading = true;
      const { data } = await this.statisticsTop();
      this.topData = data;
      this.topLoading = false;
    },
  },
  mounted() {
    this.init();
    this.getDetail();
  },
};
</script>

<style scoped>
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-center {
  align-items: center;
}
.justify-around {
  justify-content: space-between;
}
.total-box {
  height: 120px;
  width: calc(16.6% - 20px);
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.total-name {
  font-size: 30px;
  color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 8px 0 0 8px;
  background: #cccccc;
  width: 30%;
  height: 120px;
  line-height: 120px;
}
.total-info {
  width: calc(70%);
  text-align: center;
  height: 100%;
}
.total-info-num {
  font-size: 20px;
  line-height: 85px;
}
.total-info-txt {
}
</style>
